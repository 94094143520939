import React from 'react';
import './WelcomeCard.css'; // Custom styles

const WelcomeCard = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card welcome-card text-center shadow-lg p-4">
        <div className="card-body">
          <h2 className="card-title mb-4">Welcome to Coconut Market App 🌴</h2>
          <p className="card-text">
            Discover amazing deals and connect with buyers and sellers. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;
